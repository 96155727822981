@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Saira Semi Condensed', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Saira Semi Condensed' !important;

}


/* Animated gradient background */
.bg-animated-gradient {
  background: linear-gradient(270deg, #78716C, #57534E, #44403C, #78716C);
  background-size: 400% 400%;
  animation: gradient-x 8s ease infinite;
}

/* .mapboxgl-ctrl-attribution {
  position: absolute !important;
  top: 10px !important;
  left: 10px !important;
  right: auto !important;
  bottom: auto !important;
} */

.mapboxgl-ctrl-attrib {
  position: absolute !important;
  /* z-index: 1000; */
  bottom: 0px !important; /* Adjust this value if needed */
  right: 0px !important;
  background: rgba(255, 255, 255, 0.7);
  margin: 0px;
  padding: 10px;
  font-size: 12px;
  line-height: 0.2;
  z-index: 1000;
  border-top-left-radius: 3px
}

/* .deckgl-map .mapboxgl-ctrl {
  z-index: 1;
} */

/* .custom-attribution {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.8);
  padding: 5px;
  font-size: 12px;
  z-index: 10;
} */

/* Tooltip styling */
.tooltip {
  @apply absolute bg-white p-2 rounded shadow-lg text-sm z-10;
}

.card {
  @apply bg-stone-50 p-4 rounded shadow hover:shadow-md;
}


